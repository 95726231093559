import React from 'react';

import * as styles from './Image.module.css';

const getImage = (imageData) => imageData.images.sources[0];
const getImagePlaceholder = (imageData) => imageData.placeholder.fallback;
const getFallbackImage = (imageData) => imageData.images.fallback;


export default ({ image, alt }) => (
    <div className={styles.imgWrap}>
        <div aria-hidden="true" className={styles.imgPadding}></div>
        <img
            className={styles.imgPlaceholder}
            aria-hidden="true"
            decoding="async"
            src={getImagePlaceholder(image)}
            alt=""
        />
        <picture>
            <source
                type={getImage(image).type}
                srcSet={getImage(image).srcSet}
                sizes={getImage(image).size}
            />
            <img
                className={styles.img}
                width={image.width}
                height={image.height}
                // data-main-image=""
                sizes={getFallbackImage(image) ? getFallbackImage(image).size : getImage(image).size}
                decoding="async"
                loading="lazy"
                srcSet={getFallbackImage(image) ? getFallbackImage(image).srcSet : getImage(image).srcSet}
                alt={alt}
            />
        </picture>
    </div>
)
