import React, { useMemo } from 'react';
import { Link } from 'gatsby';
// import { GatsbyImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import Image from '../components/Image';
import Container from './container';
import Tags from './tags';
import * as styles from './article-preview.module.css';



const ArticlePreview = ({ posts }) => {

    const articles = useMemo(() => {

        if (!posts) return null;
        if (!Array.isArray(posts)) return null;
        
        return (
            posts.map((post) => (
                <li key={post.slug}>
                    <Link to={`/article/${post.slug}/`} className={styles.link}>
                        {/* <GatsbyImage alt={post.title} image={post.heroImage.gatsbyImageData} /> */}
                        
                        <Image alt={post.title} image={post.heroImage.gatsbyImageData}></Image>

                        <h2 className={styles.title}>{post.title}</h2>
                        <div className={styles.description}>
                            {post.description?.raw && renderRichText(post.description)}
                        </div>
                    </Link>
                    <div className={styles.meta}>
                        <small className="meta">{post.publishDate}</small>
                        <Tags tags={post.tags} />
                    </div>
                </li>
            ))
        );

    }, [posts]);

  return (
    <Container>
        <ul className={styles.articleList}>
            {articles}
        </ul>
    </Container>
  );

}

export default React.memo(ArticlePreview);
